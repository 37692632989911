import React, { useEffect } from 'react'
import './AazikoContainerTraking.scss';
import TrackingDetails from './TrackingDetails';
export default function AazikoContainerTraking() {
  useEffect(() => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
    })
}, [])
  return (
    <div>
        <TrackingDetails/>
    </div>
  )
}
