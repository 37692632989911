import React from "react";
import "./FreightShipping.scss";
export default function FreightShipping() {
  return (
    <div className="freight-shipping-section">
      <div className="container">
        <div className="freight-shipping-alignment">
          <div className="freight-shipping-heading">
            <h4>Freight, shipping and chartering glossary</h4>
          </div>

          <div className="freight-shipping-body-alignment">
            <div className="freight-shipping-body-box">
              <div className="freight-shipping-body-top-alignment">
                <p>A</p>
                <p>B</p>
                <p>C</p>
                <p>D</p>
                <p>E</p>
                <p>F</p>
                <p>G</p>
                <p>H</p>
                <p>I</p>
                <p>J</p>
                <p>K</p>
                <p>L</p>
                <p>M</p>
                <p>N</p>
                <p>O</p>
                <p>P</p>
                <p>Q</p>
                <p>R</p>
                <p>S</p>
                <p>T</p>
                <p>U</p>
                <p>V</p>
                <p>W</p>
                <p>X</p>
                <p>Y</p>
                <p>Z</p>
              </div>

              <div className="freight-shipping-details-table-alignment table-responsive ">
                <table cellPadding={0} cellSpacing={0}>
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Meaning</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>ABC analysis</td>
                      <td className="all-notes">
                        A method for classifying company resources according to their importance with respect to stocks of raw materials, components,
                        suppliers, customers, etc.
                      </td>
                    </tr>
                    <tr>
                      <td>Accessibility</td>
                      <td className="all-notes">A carrier's ability to provide transportation services from an origin to a destination.</td>
                    </tr>{" "}
                    <tr>
                      <td>Accessorial charges</td>
                      <td className="all-notes">
                        A carrier's fee for additional services specified in the contract, such as loading, unloading, pickup, and delivery.
                      </td>
                    </tr>
                    <tr>
                      <td>Action message</td>
                      <td className="all-notes">An alert that an MRP or DRP system generates to modify an existing planned or approved order.</td>
                    </tr>
                    <tr>
                      <td>Active stock</td>
                      <td className="all-notes">
                        Goods ensuring the continuity of production supplies or sale of products between two consecutive deliveries.
                      </td>
                    </tr>
                    <tr>
                      <td>Activity-Based Costing</td>
                      <td className="all-notes">
                        A method of cost managing associated with the operations of the physical movement of goods, by type of activity and finished
                        product.
                      </td>
                    </tr>
                    <tr>
                      <td>Additional Commercial Invoice</td>
                      <td className="all-notes">An invoice with additional information.</td>
                    </tr>
                    <tr>
                      <td>Advanced shipment notice</td>
                      <td className="all-notes">
                        A list with the designation of the goods transmitted to a customer or consignor (sometimes includes the expected arrival
                        time).
                      </td>
                    </tr>{" "}
                    <tr>
                      <td>Affreightment, Contract Of</td>
                      <td className="all-notes">
                        An agreement between the shipowner and the charterer on the hiring of a ship (all or part of its premises) for the carriage of
                        goods. In this case, a consignor undertakes to pay the established fee (freight) for transportation, and a carrier - to
                        deliver the goods to the port of destination.
                      </td>
                    </tr>
                    <tr>
                      <td>Agency Fee</td>
                      <td className="all-notes">
                        Payment of agent services for servicing a vessel in a port, that is, for an agent performing the usual functions of
                        representing the interests of a shipowner and assistance to the ship's captain in a port. Sometimes called attendance fee.
                      </td>
                    </tr>
                    <tr>
                      <td>Agency tariff</td>
                      <td className="all-notes">Tariffs of an agent for his/her services for carriers.</td>
                    </tr>
                    <tr>
                      <td>Agent</td>
                      <td className="all-notes">
                        An authorized person (entity or individual) performing certain actions on behalf of another person (principal) on his behalf
                        and in his interests.
                      </td>
                    </tr>
                    <tr>
                      <td>Agglomeration</td>
                      <td className="all-notes">Form of combining different companies into a whole by location.</td>
                    </tr>
                    <tr>
                      <td>Aggregate tender rate</td>
                      <td className="all-notes">
                        A special rate for a shipper if you offer more than 2 class-related shipments at the same time and one place.
                      </td>
                    </tr>
                    <tr>
                      <td>Air cargo / Air freight</td>
                      <td className="all-notes">Transportation of goods by an air carrier.</td>
                    </tr>
                    <tr>
                      <td>Air Cargo Agent</td>
                      <td className="all-notes">
                        A transport company that provides customers with a wide range of services to simplify the transport of goods by air.
                      </td>
                    </tr>
                    <tr>
                      <td>Air Cargo Containers</td>
                      <td className="all-notes">
                        This is a pallet or container used to transport air cargo. Air Cargo containers fall into three categories: 1) air cargo
                        pallets 2) lower deck containers 3) box type containers.
                      </td>
                    </tr>
                    <tr>
                      <td>Air Carrier</td>
                      <td className="all-notes">Freight forwarding company that provides air transportation services via air.</td>
                    </tr>{" "}
                    <tr>
                      <td>Air taxi</td>
                      <td className="all-notes">Public means of transport are designed to carry passengers for a fee by air.</td>
                    </tr>
                    <tr>
                      <td>Air Waybill</td>
                      <td className="all-notes">
                        A document issued by a consignor or his agent which confirms the existence of an agreement between a shipper and a carrier on
                        the carriage of goods by carrier’s airlines.
                      </td>
                    </tr>
                    <tr>
                      <td>Airport and Airway Trust Fund (AATF)</td>
                      <td className="all-notes">
                        A fund that provides funding for federal obligations under the United States aviation system through several aviation-related
                        excise taxes.
                      </td>
                    </tr>
                    <tr>
                      <td>Alameda Corridor Surcharge (ACS)</td>
                      <td className="all-notes">Surcharge for a container transported by rail through the ports of Long Beach and Los Angeles.</td>
                    </tr>
                    <tr>
                      <td>All Water</td>
                      <td className="all-notes">The term used when transportation is carried out exclusively by water.</td>
                    </tr>
                    <tr>
                      <td>All-cargo carrier</td>
                      <td className="all-notes">An air carrier that carries only cargo.</td>
                    </tr>
                    <tr>
                      <td>Amazon Reference ID</td>
                      <td className="all-notes">
                        A unique number used by Amazon.com to identify Fulfillment by Amazon (FBA) shipments upon arrival at the warehouse.
                      </td>
                    </tr>
                    <tr>
                      <td>Antidumping Duties (ADD)</td>
                      <td className="all-notes">
                        Determining the correct transportation charges due the carrier; auditing involves checking the freight bill for errors,
                        correct rate, and weight.
                      </td>
                    </tr>
                    <tr>
                      <td>Any-quantity rate</td>
                      <td className="all-notes">A rate that doesn’t depend on the quantity of cargo and is the same for any cargo.</td>
                    </tr>
                    <tr>
                      <td>Arrival Notice</td>
                      <td className="all-notes">
                        Notification of the estimated time of arrival of the vessel at a specific location (normally the destination).
                      </td>
                    </tr>
                    <tr>
                      <td>Assignment</td>
                      <td className="all-notes">
                        One party to an agreement, contract, or financial instrument, with the consent of another party, transfers the rights,
                        obligations, responsibilities, and benefits to a third party.
                      </td>
                    </tr>
                    <tr>
                      <td>Audit</td>
                      <td className="all-notes">The term is used to determine the accuracy of freight bills.</td>
                    </tr>
                    <tr>
                      <td>Auditing </td>
                      <td className="all-notes">
                        Assessment of the company's activities for compliance with certain criteria and requirements, in accordance with regulatory
                        documents.
                      </td>
                    </tr>
                    <tr>
                      <td>Automate Manifest System (AMS) </td>
                      <td className="all-notes">
                        Assessment of the company's activities for compliance with certain criteria and requirements, in accordance with regulatory
                        documents.
                      </td>
                    </tr>
                    <tr>
                      <td>Average cost </td>
                      <td className="all-notes">
                        Assessment of the company's activities for compliance with certain criteria and requirements, in accordance with regulatory
                        documents.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
