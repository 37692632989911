import React from 'react'
import "./AboutAazikoCapital.scss";
export default function AboutAazikoCapital() {
    return (
        <div className="about-aaziko-capital-section">
            <div className="container">
                <div className="about-aaziko-capital-alignment">
                    <div className="about-aaziko-heading-alignment">
                        <h4>About Aaziko Capital</h4>
                    </div>

                    <div className="about-aaziko-all-child-details-alignment">
                        <div className="about-aaziko-child-details-alignment">
                            <p>Trade Financed</p>
                            <h5>$3 <br />
                                Billion+</h5>
                        </div>
                        <div className="about-aaziko-child-details-alignment">
                            <p>Buyers & Suppliers</p>
                            <h5>6,000
                                <br />
                                Customers</h5>
                        </div>
                        <div className="about-aaziko-child-details-alignment">
                            <p>Trade Routes</p>
                            <h5>100+
                                <br />
                                Countries</h5>
                        </div>
                        <div className="about-aaziko-child-details-alignment">
                            <p>Fastest Approvals</p>
                            <h5>50,000+
                                <br />
                                Transactions</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
