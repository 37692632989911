import React from 'react'
import "./WhatWeAreSteps.scss";
export default function WhatWeAreSteps() {
    return (
        // <div className="what-we-are-steps-section">
        //     <div className="what-we-are-steps-banner">
        //         <div className="container">

        //             <div className="what-we-are-step-heading-alignment">
        //                 <h6>What We Are</h6>
        //                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. </p>
        //             </div>


        //             <div className="what-we-are-step-body-details-alignment">
        //                 <div className="what-we-are-all-step-details-alignment">
        //                     <div className="what-we-step-alignment">

        //                         <div className="what-we-step-number-alignment">
        //                             <h5>Step 1</h5>
        //                             <p>Submit your
        //                                 Request</p>
        //                         </div>
        //                     </div>

        //                     <div className="what-we-step-alignment step-2-details">
        //                         {/* <svg xmlns="http://www.w3.org/2000/svg" width="252" height="154" viewBox="0 0 252 154" fill="none">
        //                             <path d="M56.1366 0H252L196.683 77L252 154H56.1366L0 77L56.1366 0Z" fill="white" />
        //                         </svg> */}

        //                         <div className="what-we-step-number-alignment step-2-number-details">
        //                             <h5>Step 2</h5>
        //                             <p>Review
        //                                 Quatation</p>
        //                         </div>
        //                     </div>


        //                     <div className="what-we-step-alignment step-2-details">
        //                         {/* <svg xmlns="http://www.w3.org/2000/svg" width="252" height="154" viewBox="0 0 252 154" fill="none">
        //                             <path d="M56.1366 0H252L196.683 77L252 154H56.1366L0 77L56.1366 0Z" fill="white" />
        //                         </svg> */}

        //                         <div className="what-we-step-number-alignment step-2-number-details">
        //                             <h5>Step 3</h5>
        //                             <p>Product &
        //                                 Documentation
        //                                 Review</p>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
        <></>
        )
}
