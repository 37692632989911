import React from 'react'
import './TalkToExpert.scss';
export default function TalkToExpert() {
  return (
    <div className="talk-section">
    <h2>Talk to an expert</h2>
    <p>
        Get in contact with a member of our dedicated reefer team
        , who will be able to assist with all reefer enquiries and bookings.
    </p>
    <div className="button-center">
        <button>Get started</button>
    </div>
</div>
  )
}
