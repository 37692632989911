import React from "react";
import "./OurTraindingVideo.scss";
import DemoVideoImg from "../../../../assets/Image/out-video-demo-img.png";
import DemoVideoImg005 from "../../../../assets/Image/005.png";
import DemoVideoImg006 from "../../../../assets/Image/006.png";
import DemoVideoImg007 from "../../../../assets/Image/007.png";
import DemoVideoImg008 from "../../../../assets/Image/008.png";
import DemoVideoImg009 from "../../../../assets/Image/009.png";
import YouTubeIcon from "../../../../assets/icons/you-tube-icon.svg";
export default function OurTraindingVideo() {
  return (
    <div className="our-trainding-video-section">
      <div className="our-trainding-video-grid">
        <div className="our-trainding-video-grid-item">
          <div className="our-trainding-video-grid-box">
            <div className="our-trainding-video-img">
              <img src={DemoVideoImg} alt="DemoVideoImg" />
            </div>
            <div className="our-trainding-video-details-alignment">
              <div>
                <h6>Most Easiest & Trustable International Trading Platform</h6>
              </div>
              <div className="our-trainding-video-right-side-alignment">
                <div className="our-traimding-youtube-icon">
                  <img src={YouTubeIcon} alt="YouTubeIcon" />
                </div>
                <div className="subscribe-button-alignment">
                  <button>SUBCRIBE</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="our-trainding-video-grid-item">
          <div className="our-trainding-video-grid-box">
            <div className="our-trainding-video-img">
              <iframe
                src="https://www.youtube.com/embed/eBMJQMJM4ao"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
            <div className="our-trainding-video-details-alignment">
              <div>
                <h6>The Best Supply Model || English</h6>
              </div>
              <div className="our-trainding-video-right-side-alignment">
                <div className="our-traimding-youtube-icon">
                  <img src={YouTubeIcon} alt="YouTubeIcon" />
                </div>
                <div className="subscribe-button-alignment">
                  <button>SUBCRIBE</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="our-trainding-video-grid-item">
          <div className="our-trainding-video-grid-box">
            <div className="our-trainding-video-img">
              <iframe
                src="https://www.youtube.com/embed/2pFBZDUo1pk"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
            <div className="our-trainding-video-details-alignment">
              <div>
                <h6>Make Your Business Global With Aaziko.com || English</h6>
              </div>
              <div className="our-trainding-video-right-side-alignment">
                <div className="our-traimding-youtube-icon">
                  <img src={YouTubeIcon} alt="YouTubeIcon" />
                </div>
                <div className="subscribe-button-alignment">
                  <button>SUBCRIBE</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="our-trainding-video-grid-item">
          <div className="our-trainding-video-grid-box">
            <div className="our-trainding-video-img">
              <iframe
                src="https://www.youtube.com/embed/CFNziklIDR8"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
            <div className="our-trainding-video-details-alignment">
              <div>
                <h6>Finding India's Manufacturing || English</h6>
              </div>
              <div className="our-trainding-video-right-side-alignment">
                <div className="our-traimding-youtube-icon">
                  <img src={YouTubeIcon} alt="YouTubeIcon" />
                </div>
                <div className="subscribe-button-alignment">
                  <button>SUBCRIBE</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="our-trainding-video-grid-item">
          <div className="our-trainding-video-grid-box">
            <div className="our-trainding-video-img">
              <iframe
                src="https://www.youtube.com/embed/Xg0jF5CpXDU"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
            <div className="our-trainding-video-details-alignment">
              <div>
                <h6>Buying At Manufacturing Price || English</h6>
              </div>
              <div className="our-trainding-video-right-side-alignment">
                <div className="our-traimding-youtube-icon">
                  <img src={YouTubeIcon} alt="YouTubeIcon" />
                </div>
                <div className="subscribe-button-alignment">
                  <button>SUBCRIBE</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="our-trainding-video-grid-item">
          <div className="our-trainding-video-grid-box">
            <div className="our-trainding-video-img">
              <img src={DemoVideoImg007} alt="DemoVideoImg007" />
            </div>
            <div className="our-trainding-video-details-alignment">
              <div>
                <h6>Grow Business || Indian Manufacturers</h6>
              </div>
              <div className="our-trainding-video-right-side-alignment">
                <div className="our-traimding-youtube-icon">
                  <img src={YouTubeIcon} alt="YouTubeIcon" />
                </div>
                <div className="subscribe-button-alignment">
                  <button>SUBCRIBE</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="our-trainding-video-grid-item">
          <div className="our-trainding-video-grid-box">
            <div className="our-trainding-video-img">
              <img src={DemoVideoImg008} alt="DemoVideoImg008" />
            </div>
            <div className="our-trainding-video-details-alignment">
              <div>
                <h6>GUJARAT ROAD SHOW</h6>
              </div>
              <div className="our-trainding-video-right-side-alignment">
                <div className="our-traimding-youtube-icon">
                  <img src={YouTubeIcon} alt="YouTubeIcon" />
                </div>
                <div className="subscribe-button-alignment">
                  <button>SUBCRIBE</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="our-trainding-video-grid-item">
          <div className="our-trainding-video-grid-box">
            <div className="our-trainding-video-img">
              <img src={DemoVideoImg009} alt="DemoVideoImg009" />
            </div>
            <div className="our-trainding-video-details-alignment">
              <div>
                <h6>Trustful International Trading Platform</h6>
              </div>
              <div className="our-trainding-video-right-side-alignment">
                <div className="our-traimding-youtube-icon">
                  <img src={YouTubeIcon} alt="YouTubeIcon" />
                </div>
                <div className="subscribe-button-alignment">
                  <button>SUBCRIBE</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="our-trainding-video-grid-item">
          <div className="our-trainding-video-grid-box">
            <div className="our-trainding-video-img">
              <iframe
                src="https://www.youtube.com/embed/1twIENXJXqc"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
            <div className="our-trainding-video-details-alignment">
              <div>
                <h6>GUJARAT INDUSTRIAL CORPORAT || Aaziko.com</h6>
              </div>
              <div className="our-trainding-video-right-side-alignment">
                <div className="our-traimding-youtube-icon">
                  <img src={YouTubeIcon} alt="YouTubeIcon" />
                </div>
                <div className="subscribe-button-alignment">
                  <button>SUBCRIBE</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="our-trainding-video-grid-item">
          <div className="our-trainding-video-grid-box">
            <div className="our-trainding-video-img">
              <iframe
                src="https://www.youtube.com/embed/Qv3KbpLRUMY"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
            <div className="our-trainding-video-details-alignment">
              <div>
                <h6>GUJARAT INDUSTRIAL CORPORAT || Aaziko.com</h6>
              </div>
              <div className="our-trainding-video-right-side-alignment">
                <div className="our-traimding-youtube-icon">
                  <img src={YouTubeIcon} alt="YouTubeIcon" />
                </div>
                <div className="subscribe-button-alignment">
                  <button>SUBCRIBE</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* asas */}
      </div>
    </div>
  );
}
// Changes in this code scss file