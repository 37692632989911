import React from "react";
import "./LSQGrowyourBusiness.scss";
export default function LSQGrowyourBusiness() {
  return (
    <div className="lsq-grow-your-bussiness-section">
      <div className="container">
        <div className="lsq-grow-bussiness-alignment">
          <h4>Ready to Grow Your Business?</h4>
          <div className="lsq-grow-bussiness-button">
            <div className="chat-with-consultant-button">
              <button>Chat with consultant</button>
            </div>
            <div className="start-selling-button">
              <button>Start selling</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
