import React from 'react'
import './Confidence.scss';
export default function Confidence() {
  return (
    <div>
        <div className='confidence-banner'>
            <div className='container'>
                <div className='items-center'>
                    <div>
                        <p>Order with Confidence</p>
                        <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </span>
                    </div>
                    <div>
                        <button>Learn More</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
