import React from "react";
import "./LogisticsServiceBanner.scss";
export default function LogisticsServiceBanner() {
  return (
    <div>
      <div className="logistic-service-banner">
        <div className="w-full">
          <div className="container">
            <div className="text-style">
              <h1>Ship to the Us With De Well</h1>
                <p>
                Making booking, tracking and managing shipments easy
                </p>
                <button>
                    Contact us
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
