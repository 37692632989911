import React from "react";
import "./LSQOtherDetails.scss";
import GMedalImg from "../../../assets/Image/g-medal-img.png";
export default function LSQOtherDetails() {
  return (
    <div className="lsq-other-details-section">
      <div className="container">
        <div className="lsq-other-details-tab-alignment">
          <p>Recommended Quotations</p>
          <p>Seize your opportunities and reach millions of buyers</p>
        </div>

        <div className="lsq-other-details-box-alignment">
          <div className="lsq-other-details-box-grid">
            <div className="lsq-other-details-box-grid-item">
              <div className="lsq-other-details-box-child">
                <h6>Abstract Eagle Wing statue Resin craft gold color</h6>
                <div className="lsq-other-child-details-alignment">
                  <div className="lsq-other-child-grid">
                    <div className="lsq-other-name-alignment">
                      <span>Destination:</span>
                      <p>Asia</p>
                    </div>
                    <div className="lsq-other-name-alignment">
                      <span>Quantity:</span>
                      <p>Piece/Pieces</p>
                    </div>
                    <div className="lsq-other-name-alignment">
                      <span>Time Left:</span>
                      <p>6 day</p>
                    </div>
                  </div>
                </div>
                <div className="lsq-other-details-notes">
                  <p>
                    c. It would be like the attached picture, but I would need it in three prints instead of just this one. The quantity to start
                    would be 200 per print, for a total of 600. I would plan to produc....
                  </p>
                </div>
                <div className="gold-medal-box-details">
                  <img src={GMedalImg} alt="GMedalImg" />
                </div>
                <div className="lsq-upgrade-button">
                  <button>Upgrade and Quote Now</button>
                </div>
              </div>
            </div>
            <div className="lsq-other-details-box-grid-item">
              <div className="lsq-other-details-box-child">
                <h6>Portable Silicone Wax Melt Machine</h6>

                <div className="lsq-other-child-details-alignment">
                  <div className="lsq-other-child-grid">
                    <div className="lsq-other-name-alignment">
                      <span>Destination:</span>
                      <p>Asia</p>
                    </div>
                    <div className="lsq-other-name-alignment">
                      <span>Quantity:</span>
                      <p>Piece/Pieces</p>
                    </div>
                    <div className="lsq-other-name-alignment">
                      <span>Time Left:</span>
                      <p>5 day</p>
                    </div>
                  </div>
                </div>

                <div className="lsq-other-details-notes">
                  <p>
                    c. It would be like the attached picture, but I would need it in three prints instead of just this one. The quantity to start
                    would be 200 per print, for a total of 600. I would plan to produc....
                  </p>
                </div>
                <div className="gold-medal-box-details">
                  <img src={GMedalImg} alt="GMedalImg" />
                </div>
                <div className="lsq-upgrade-button">
                  <button>Upgrade and Quote Now</button>
                </div>
              </div>
            </div>
          </div>
          <div className="lsq-other-details-box-grid">
            <div className="lsq-other-details-box-grid-item">
              <div className="lsq-other-details-box-child">
                <h6>Thigh Master Thigh Toner Kegel Exerciser Pelvic Floor Trainer Hip Trainer Inner Thigh Exercise Equipment </h6>
                <div className="lsq-other-child-details-alignment second-alignment">
                  <div className="lsq-other-child-grid">
                    <div className="lsq-other-name-alignment">
                      <span>Destination:</span>
                      <p>NorthAmerica</p>
                    </div>
                    <div className="lsq-other-name-alignment">
                      <span>Quantity:</span>
                      <p>Piece/Pieces</p>
                    </div>
                    <div className="lsq-other-name-alignment">
                      <span>Time Left:</span>
                      <p>8 day</p>
                    </div>
                  </div>
                </div>
                <div className="lsq-other-details-notes">
                  <p>
                    c. It would be like the attached picture, but I would need it in three prints instead of just this one. The quantity to start
                    would be 200 per print, for a total of 600. I would plan to produc....
                  </p>
                </div>
                <div className="gold-medal-box-details">
                  <img src={GMedalImg} alt="GMedalImg" />
                </div>
                <div className="lsq-upgrade-button">
                  <button>Upgrade and Quote Now</button>
                </div>
              </div>
            </div>
            <div className="lsq-other-details-box-grid-item">
              <div className="lsq-other-details-box-child">
                <h6>Gibbits for croc shoes</h6>

                <div className="lsq-other-child-details-alignment">
                  <div className="lsq-other-child-grid">
                    <div className="lsq-other-name-alignment">
                      <span>Destination:</span>
                      <p>NorthAmerica</p>
                    </div>
                    <div className="lsq-other-name-alignment">
                      <span>Quantity:</span>
                      <p>Piece/Pieces</p>
                    </div>
                    <div className="lsq-other-name-alignment">
                      <span>Time Left:</span>
                      <p>5 day</p>
                    </div>
                  </div>
                </div>

                <div className="lsq-other-details-notes">
                  <p>
                    c. It would be like the attached picture, but I would need it in three prints instead of just this one. The quantity to start
                    would be 200 per print, for a total of 600. I would plan to produc....
                  </p>
                </div>
                <div className="gold-medal-box-details">
                  <img src={GMedalImg} alt="GMedalImg" />
                </div>
                <div className="lsq-upgrade-button">
                  <button>Upgrade and Quote Now</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="lsq-check-more-button-alignment">
          <button>Check More Opportunities</button>
        </div>
      </div>
    </div>
  );
}
