import React from 'react'
import "./orderDelivery.scss";

export default function Notfound() {
    return (
        <div className="orderTicketMain-section">
            <div className="orderDelivery-section">
                <div className="issue-section">
                    <div className="heading-alignment">
                        <h4>Data not Found</h4>
                    </div>

                  
                </div>
            </div>
        </div>
    )
}
