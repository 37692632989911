import React from 'react'
import './FeaturedpartnersCard.scss';
import HeliumImage from '../../../../assets/icons/helium.svg';
export default function FeaturedpartnersCard() {
  return (
    <div>
        <div className='featured-partners-card-all-content-alignment-for-page'>
            <div className='result-found'>
                <span>13 results found</span>
            </div>
            <div className='grid'>
                <div className='grid-items'>
                    <div className='image-center-alignment'>
                        <img src={HeliumImage} alt="HeliumImage"/>
                    </div>
                    <span>
                        Product research and sourcing is now smarter 
                        and simpler with this Chrome Extension featuring a demand analyzer and supplier finder. Learn more
                    </span>
                </div>
                <div className='grid-items'>
                    <div className='image-center-alignment'>
                        <img src={HeliumImage} alt="HeliumImage"/>
                    </div>
                    <span>
                        Product research and sourcing is now smarter 
                        and simpler with this Chrome Extension featuring a demand analyzer and supplier finder. Learn more
                    </span>
                </div>
                <div className='grid-items'>
                    <div className='image-center-alignment'>
                        <img src={HeliumImage} alt="HeliumImage"/>
                    </div>
                    <span>
                        Product research and sourcing is now smarter 
                        and simpler with this Chrome Extension featuring a demand analyzer and supplier finder. Learn more
                    </span>
                </div>
                <div className='grid-items'>
                    <div className='image-center-alignment'>
                        <img src={HeliumImage} alt="HeliumImage"/>
                    </div>
                    <span>
                        Product research and sourcing is now smarter 
                        and simpler with this Chrome Extension featuring a demand analyzer and supplier finder. Learn more
                    </span>
                </div>
                <div className='grid-items'>
                    <div className='image-center-alignment'>
                        <img src={HeliumImage} alt="HeliumImage"/>
                    </div>
                    <span>
                        Product research and sourcing is now smarter 
                        and simpler with this Chrome Extension featuring a demand analyzer and supplier finder. Learn more
                    </span>
                </div>
                <div className='grid-items'>
                    <div className='image-center-alignment'>
                        <img src={HeliumImage} alt="HeliumImage"/>
                    </div>
                    <span>
                        Product research and sourcing is now smarter 
                        and simpler with this Chrome Extension featuring a demand analyzer and supplier finder. Learn more
                    </span>
                </div>
                <div className='grid-items'>
                    <div className='image-center-alignment'>
                        <img src={HeliumImage} alt="HeliumImage"/>
                    </div>
                    <span>
                        Product research and sourcing is now smarter 
                        and simpler with this Chrome Extension featuring a demand analyzer and supplier finder. Learn more
                    </span>
                </div>
                <div className='grid-items'>
                    <div className='image-center-alignment'>
                        <img src={HeliumImage} alt="HeliumImage"/>
                    </div>
                    <span>
                        Product research and sourcing is now smarter 
                        and simpler with this Chrome Extension featuring a demand analyzer and supplier finder. Learn more
                    </span>
                </div>
                <div className='grid-items'>
                    <div className='image-center-alignment'>
                        <img src={HeliumImage} alt="HeliumImage"/>
                    </div>
                    <span>
                        Product research and sourcing is now smarter 
                        and simpler with this Chrome Extension featuring a demand analyzer and supplier finder. Learn more
                    </span>
                </div>
                <div className='grid-items'>
                    <div className='image-center-alignment'>
                        <img src={HeliumImage} alt="HeliumImage"/>
                    </div>
                    <span>
                        Product research and sourcing is now smarter 
                        and simpler with this Chrome Extension featuring a demand analyzer and supplier finder. Learn more
                    </span>
                </div>
                <div className='grid-items'>
                    <div className='image-center-alignment'>
                        <img src={HeliumImage} alt="HeliumImage"/>
                    </div>
                    <span>
                        Product research and sourcing is now smarter 
                        and simpler with this Chrome Extension featuring a demand analyzer and supplier finder. Learn more
                    </span>
                </div>
                <div className='grid-items'>
                    <div className='image-center-alignment'>
                        <img src={HeliumImage} alt="HeliumImage"/>
                    </div>
                    <span>
                        Product research and sourcing is now smarter 
                        and simpler with this Chrome Extension featuring a demand analyzer and supplier finder. Learn more
                    </span>
                </div>
                <div className='grid-items'>
                    <div className='image-center-alignment'>
                        <img src={HeliumImage} alt="HeliumImage"/>
                    </div>
                    <span>
                        Product research and sourcing is now smarter 
                        and simpler with this Chrome Extension featuring a demand analyzer and supplier finder. Learn more
                    </span>
                </div>
                <div className='grid-items'>
                    <div className='image-center-alignment'>
                        <img src={HeliumImage} alt="HeliumImage"/>
                    </div>
                    <span>
                        Product research and sourcing is now smarter 
                        and simpler with this Chrome Extension featuring a demand analyzer and supplier finder. Learn more
                    </span>
                </div>
                <div className='grid-items'>
                    <div className='image-center-alignment'>
                        <img src={HeliumImage} alt="HeliumImage"/>
                    </div>
                    <span>
                        Product research and sourcing is now smarter 
                        and simpler with this Chrome Extension featuring a demand analyzer and supplier finder. Learn more
                    </span>
                </div>
                <div className='grid-items'>
                    <div className='image-center-alignment'>
                        <img src={HeliumImage} alt="HeliumImage"/>
                    </div>
                    <span>
                        Product research and sourcing is now smarter 
                        and simpler with this Chrome Extension featuring a demand analyzer and supplier finder. Learn more
                    </span>
                </div>
            </div>
        </div>
    </div>
  )
}
