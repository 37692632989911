import React, { useEffect } from 'react'
import "./BuyerCollaborate.scss";
import BuyerCollaborationApproach from './BuyerCollaborationApproach';
import InitiatingCollaboration from './InitiatingCollaboration';
export default function BuyerCollaborate() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
    }, [])
    return (
        <div className="buyer-collaborate-section">
            <div className="container">
                <div className="buyer-collaborate-main-details">
                    <div className="buyer-collaborate-main-alignment">
                        <div className="buyer-collaborate-banner-details">
                            <h4>Collaborate with <p><span className="a-text">A</span><span className="a-2-text">a</span><span className="z-text">z</span><span className="i-text">i</span><span className="ko-text">k</span><span className="ko-text">o</span></p></h4>
                            <p>Welcome to the Collaborate with Aaziko page. We believe in the power of partnerships and collaborations to drive innovation and create mutual growth. At Aaziko, we are open to exploring collaboration opportunities with like-minded businesses and partners in the import-export industry and beyond.</p>
                        </div>
                    </div>
                </div>

            </div>
            <BuyerCollaborationApproach />
            <InitiatingCollaboration />
        </div>
    )
}
