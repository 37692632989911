import React from "react";
import "./ShippingLines.scss";
import ShipFillIcon from "../../assets/icons/ship-fill.svg";
import TrackIcon from "../../assets/icons/track1-icon.svg";
import RealIcon from "../../assets/icons/real1-icon.svg";
import SeaIcon from "../../assets/icons/sea1-icon.svg";
export default function ShippingLines() {
  return (
    <div className="shipping-lines-section">
      <div className="container">
        <div className="shipping-lines-alignment">
          <div className="shipping-lines-heading ">
            <img src={ShipFillIcon} alt="ShipFillIcon" />

            <h4>Shipping Lines</h4>
          </div>

          <div className="shipping-lines-body-alignment">
            <div className="shipping-lines-box-alignment">
              <div className="shipping-lines-box-heading">
                <h6>Existing</h6>
              </div>

              <div className="shipping-lines-box-details">
                <div className="shipping-lines-grid-alignment">
                  <div className="shipping-lines-grid-item ">
                    <p>ACL</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>Econship</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>K Line</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>SAMSKIP</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>ACL</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>Econship</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>KMTC</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>SAMUDERA</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>ACL</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>EIMSKIP</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>Lancer</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>SASCO</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>ADMIRAL</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>Emkay Lines</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>LIBRA</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>SCI</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>Aiyer</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>EPS</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>LNL</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>SCI</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>AKKON</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>ESF EUROSERVICES</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>MACANDREWS</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>SCL</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>ALIANCA</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>ESL</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>MACS</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>SEABOARD MARINE</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>Allalouf</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>ESL</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>MACS</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>SEACORP</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>ALX</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>Ethiopian Shipping Line</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>Maersk</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>Sealead </p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>ANL</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>EUCON</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>Mann Lines</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>SENATOR LINES</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>ARKAS</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>EUROAFRICA</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>MARFRED</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>SEOUL LINE</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>ASYAD</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>Europe Caribbean Line</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>Marfret</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>SLF</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>AUSTRAL ASIA</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>Evergreen</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>MARINE</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>Smart Solutions Line</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>BAL CONTAINER LINE LTD</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>EWL</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>MATSON</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>SOC</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>Bengal Tiger Line</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>FEEDERLINK</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>MAXICON</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>SOFRANA</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>BENLINE</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>Feedertech</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>MESSINA</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>STINNES</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>BG FREIGHT LINE</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>FESCO</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>Milaha</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>STX PAN OCEAN</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>BLPL</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>FLS</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>MISC BERHAD</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>SYMS</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>Blue World Line</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>GCL</p>
                  </div>{" "}
                  <div className="shipping-lines-grid-item ">
                    <p>MLI</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>TARROS</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>Blue World Line</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>GEEST LINE</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>MSC</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>TASMAN ORIENT</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>BMC Lines</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>GOODRICH</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>NAVALIS</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>BOXMAN</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>GSL</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>NDS</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>TEAM LINES</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>BWL</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>GWS</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>NEPTUNE</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>TRANS ASIA LINE</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>CargoGulf</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>HAMBURG SUD</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>NEW STAR</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>Transworld</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>Transworld</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>Carpenters Shipping</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>HANJIN</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>NGPL</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>TS LINE</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>CCS</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>Hapag-Lloyd</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>NORDANA</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>TURKON</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>CEYLINE</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>HASCO</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>NORTRANS</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>Unifeeder</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>CK Line</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>HATSU MARINE</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>NSC Arkhangelsk</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>VASI</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>CMA CGM</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>Heung-A</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>NSCSA</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>VERTEX</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>CORDELIA</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>HEUNG-A</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>OACL</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>VOLTA</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>COSCO</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>Heung-A Shipping</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>OOCL</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>Wan Hai</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>Cosiarma</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>HMM</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>OTAL</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>WDS</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>CP World</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>HOLLAND MAAS</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>PACIFIC ASIA EXPRESS</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>WEC</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>Crowley Maritime</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>HYUNDAI (HM)</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>PDL</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>W.E.C. Lines</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>CSL</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>ICL</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>PFL</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>Westwood Shipping Lines</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>CULines</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>IDCL</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>PIL</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>White Line Shipping</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>DAL</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>Interasia</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>PROTOS</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>Win Asia</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>DAL</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>IRISL</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>QNL</p>
                  </div>{" "}
                  <div className="shipping-lines-grid-item ">
                    <p>Win Win</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>DCL</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>KALYPSO</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>Radiant</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>WSL</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>DFA Special offer</p>
                  </div>{" "}
                  <div className="shipping-lines-grid-item ">
                    <p>Kambara Kisen</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>RAL</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>XCL</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>DFDS LYS LINE</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>King Ocean Services</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>RCL</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>Yang Ming</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>Dongjin Shipping</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p></p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>Safmarine</p>
                  </div>
                  <div className="shipping-lines-grid-item ">
                    <p>ZIM</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="shipping-lines-bottom-alignment">
              <div className="shipping-lines-second-box-alignment">
                <div className="shipping-lines-second-heading">
                  <h6>Deprecated</h6>
                </div>

                <div className="shipping-lines-second-body-alignment">
                  <div className="shipping-lines-second-grid-alignment">
                    <div className="shipping-lines-second-grid-item">
                      <p>APL</p>
                    </div>
                    <div className="shipping-lines-second-grid-item">
                      <p>CCNI</p>
                    </div>
                    <div className="shipping-lines-second-grid-item">
                      <p>CP SHIPS</p>
                    </div>
                    <div className="shipping-lines-second-grid-item">
                      <p>MOL</p>
                    </div>
                    <div className="shipping-lines-second-grid-item">
                      <p>ATLANTICARGO</p>
                    </div>
                    <div className="shipping-lines-second-grid-item">
                      <p>CHINA SHIPPING</p>
                    </div>
                    <div className="shipping-lines-second-grid-item">
                      <p>DELMAS</p>
                    </div>
                    <div className="shipping-lines-second-grid-item">
                      <p>NORASIA</p>
                    </div>
                    <div className="shipping-lines-second-grid-item">
                      <p>BULCON</p>
                    </div>
                    <div className="shipping-lines-second-grid-item">
                      <p>CHL</p>
                    </div>
                    <div className="shipping-lines-second-grid-item">
                      <p>ITALIA MARITTIMA</p>
                    </div>
                    <div className="shipping-lines-second-grid-item">
                      <p>NYK</p>
                    </div>
                    <div className="shipping-lines-second-grid-item">
                      <p>CCL</p>
                    </div>
                    <div className="shipping-lines-second-grid-item">
                      <p>CONTAZ</p>
                    </div>
                    <div className="shipping-lines-second-grid-item">
                      <p>K-LINE</p>
                    </div>
                    <div className="shipping-lines-second-grid-item">
                      <p>UASC</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="shipping-lines-other-details-alignment">
                <div className="shipping-lines-other-child-box">
                  <div className="shipping-lines-left-side-alignment">
                    <div className="shipping-icon-box-alignment">
                      <img src={TrackIcon} alt="TrackIcon" />
                    </div>
                    <div className="shipping-other-details-alignment">
                      <h6>Track & Trace System</h6>
                      <p>
                        Container tracking service provides a very convenient and easy to use way of tracking the current location of containers with
                        your cargo by container number.
                      </p>
                    </div>
                  </div>
                  <div className="get-started-button">
                    <button>Get Started</button>
                  </div>
                </div>
                <div className="shipping-lines-other-child-box">
                  <div className="shipping-lines-left-side-alignment">
                    <div className="shipping-icon-box-alignment">
                      <img src={RealIcon} alt="RealIcon" />
                    </div>
                    <div className="shipping-other-details-alignment">
                      <h6>Real ocean freight transit time</h6>
                      <p>
                        Get to know the time in transit of cargo on popular container shipping lines between ports of loading and port of discharge.
                      </p>
                    </div>
                  </div>
                  <div className="get-started-button">
                    <button>Get Started</button>
                  </div>
                </div>
                <div className="shipping-lines-other-child-box">
                  <div className="shipping-lines-left-side-alignment">
                    <div className="shipping-icon-box-alignment">
                      <img src={SeaIcon} alt="SeaIcon" />
                    </div>
                    <div className="shipping-other-details-alignment">
                      <h6>Sea Lines Explorer</h6>
                      <p>Service is designed for determination of shipping lines whose vessels put in chosen port or direction.</p>
                    </div>
                  </div>
                  <div className="get-started-button">
                    <button>Get Started</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
