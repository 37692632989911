import React from 'react'
import './Getstarted.scss';
export default function Getstarted() {
  return (
    <div>
      <div className='get-started-all-content-alignment'>
        <div className='container'>
            <div className='box'>
                <h4>Get started </h4>
                <p>
                    Embark on an exciting journey of ultimate financial clarity and exceptional 
                    compliance with Aaziko. Let's turn your business vision into reality!
                </p>
                <div className='btn-center'>
                    <button>contact us </button>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}
