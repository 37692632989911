import React from "react";
import "./Setupsection.scss";
export default function Setupsection() {
  return (
    <div>
      <div className="setup-section-all-content-alignment-for-page">
        <div className="container">
          <div className="section-title">
            <h1>How to set up your Product Showcase</h1>
            <p>
              Eligible sellers can set up and manage their Product Showcase in
              the “My Aaziko” dashboards with just a few clicks. Setting up
              your product showcases is quite easy, which makes it even more
              valuable as a selling tool.
            </p>
          </div>
          <div className="grid">
            <div className="grid-items">
                <p>Open the “Products” tab</p>
                <span>
                Open the “Products'' tab. You can find this in the menu on the right hand side of your “My Aaziko” dashboard.
                </span>
            </div>
            <div className="grid-items">
                <p>Open the “Products” tab</p>
                <span>
                Open the “Products'' tab. You can find this in the menu on the right hand side of your “My Aaziko” dashboard.
                </span>
            </div>
            <div className="grid-items">
                <p>Open the “Products” tab</p>
                <span>
                Open the “Products'' tab. You can find this in the menu on the right hand side of your “My Aaziko” dashboard.
                </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
