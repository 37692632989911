import React from 'react'
import './KwaBanner.scss';
export default function KwaBanner() {
  return (
    <div>
        <div className='kwa-banner-design'>
            <div className='container'>
                <div className='grid'>
                    <div className='grid-items'>
                        <h4>
                            Keyword Advertising (KWA): The 
                            Smart Way to Advertise on Aaziko.com
                        </h4>
                        <p>
                            Offer multiple touchpoints to reach buyers through their purchasing journey with 
                            Keyword Advertising. Target strategically chosen keywords to increase qualified traffic and boost sales.
                        </p>
                        <a>Marketing Center</a>
                    </div>
                    <div className='grid-items'>
                        <div className='video-box'></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
