import React from "react";
import "./AnalysisSection.scss";
export default function AnalysisSection() {
  return (
    <div>
      <div className="analysis-section-content-alignment">
        <div className="container">
          <h6>Keyword analysis features</h6>
          <div className="card-grid">
            <div className="card-grid-items">
              <p>Historical trends</p>
              <span>
                Check the keyword search distribution for different
                countries/regions for more accurate targeting.
              </span>
            </div>
            <div className="card-grid-items">
              <p>Historical trends</p>
              <span>
                Check the keyword search distribution for different
                countries/regions for more accurate targeting.
              </span>
            </div>
            <div className="card-grid-items">
              <p>Historical trends</p>
              <span>
                Check the keyword search distribution for different
                countries/regions for more accurate targeting.
              </span>
            </div>
            <div className="card-grid-items">
              <p>Historical trends</p>
              <span>
                Check the keyword search distribution for different
                countries/regions for more accurate targeting.
              </span>
            </div>


























































             
              
               

                 
                  
                   

                    
                     
                    
          </div>
        </div>
      </div>
    </div>
  );
}
